import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-a1-nexts',
  templateUrl: './home-a1-nexts.component.html',
  styleUrls: ['./home-a1-nexts.component.css']
})
export class HomeA1NextsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
