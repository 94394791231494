import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeA1NextsComponent } from './home-a1-nexts/home-a1-nexts.component';



@NgModule({
  declarations: [HomeA1NextsComponent],
  imports: [
    CommonModule
  ]
})
export class HomeModule { }
