import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { HintMusicThModule } from './hint-music-th/hint-music-th.module';
import { HomeA1NextsComponent } from './home/home-a1-nexts/home-a1-nexts.component';
import { HintMusicThPolicyComponent } from './hint-music-th/hint-music-th-policy/hint-music-th-policy.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeA1NextsComponent },
  { path: 'privacy-policy',component: HintMusicThPolicyComponent }
]; // sets up routes constant where you define your routes

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
